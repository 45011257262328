export async function loginServer(idToken,pubKey) {
    const response = await fetch(`https://backend.sportsverse.cricket/users/login`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + idToken,
        }),
        body: JSON.stringify({"publicKey": pubKey})
      })
      console.log(response)
    return await response.json();
}

export async function whiteListUser(idToken,pubKey) {
    const response = await fetch(`https://backend.sportsverse.cricket/users/whitelist/web3airdrop`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: "Bearer " + idToken,
        }),
        body: JSON.stringify({"publicKey": pubKey})
      })
      console.log(response)
    return await response.json();
}